import Autoplay from 'embla-carousel-autoplay';
import ClassNames from 'embla-carousel-class-names';
import { Image } from '@unpic/react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselControls,
} from './hero-carousel';
import { Frame } from '#app/components/layouts/frame';

import { cn, getImageItemUrlByHandle, getImageUrl } from '#app/utils/misc.tsx';
import useScreenSize from '#app/hooks/useScreenSize.tsx';

export default function HeroCarousel({ data }: { data: HomepageProps }) {
  const delay = 3500;
  const screenSize = useScreenSize();

  const image = getImageItemUrlByHandle(data.imageCollection, 'heroGradient');
  const hpHeroGradients = image ? image : '/img/hp-hero-gradients.png';

  const getImage = (idx: number, image: any) => {
    const imgUrl = getImageUrl(screenSize, image);

    return (
      <Image
        width={3000}
        aspectRatio={1}
        src={imgUrl}
        className="slide-img z-10"
        alt=""
        priority={idx === 0}
        srcSet={image.srcset}
        loading="lazy"
        // cdn="cloudflare"
      />
    );
  };

  return (
    <Carousel
      plugins={[
        Autoplay({
          delay,
          stopOnInteraction: false,
          jump: true,
        }),
        ClassNames(),
      ]}
      opts={{ watchDrag: false }}
    >
      <CarouselContent>
        {data?.carouselItems?.map((carouselItem, idx) => (
          <CarouselItem key={idx}>
            <div className="grid w-full grid-cols-1">
              <Frame
                // align="end"
                // justify="end"
                className="!*:top-auto relative h-screen overflow-hidden *:justify-end sm:*:items-end sm:*:justify-center md:h-[calc(100vh_-_100px)]"
              >
                <div
                  className={cn(
                    'hp-text-shadow !bottom-[260px] !top-auto z-30 flex flex-col gap-x-4 gap-y-7  text-white sm:flex-row sm:flex-nowrap md:!bottom-[180px] md:mt-0 md:text-[60px] lg:text-[70px]',
                    idx === 2 || idx === 3
                      ? 'text-[54px] md:text-[50px] 2md:text-[54px]'
                      : 'text-[54px]',
                  )}
                >
                  <p className="inline-block text-center leading-[50px] -tracking-[1.12px] md:leading-[91.41px]">
                    Proven Power <span className="hidden md:inline">in</span>
                  </p>
                  <span className="text-fill-transparent hidden bg-gradient-hero-label bg-clip-text text-center leading-[50px] -tracking-[1.12px] sm:leading-[91.41px] md:inline-block">
                    {carouselItem.itemLabel}.
                  </span>
                </div>
                <p className="!bottom-[155px] !top-auto z-30 !hidden text-[15px] text-neutral-400x text-[#B3B7BA] md:!flex">
                  {carouselItem.itemSubtitle}
                </p>

                <Image
                  width={3000}
                  aspectRatio={1}
                  src={hpHeroGradients}
                  className="z-20 h-full"
                  priority={true}
                  alt=""
                />

                {carouselItem.itemImage?.[0]?.url ? (
                  <>{getImage(idx, carouselItem.itemImage?.[0])}</>
                ) : null}
              </Frame>
              <div className="w-full bg-gray-950" />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselControls slides={data?.carouselItems!} delay={delay} />
    </Carousel>
  );
}
