import React, { useRef } from 'react';
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from 'framer-motion';
import { Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Link } from '@remix-run/react';
import { Image } from '@unpic/react';
import { ArrowRight } from '@phosphor-icons/react/dist/csr/ArrowRight';

import Footer from '#app/components/footer';
import NewsletterBar from '#app/components/newsletter-bar';
import { Button } from '#app/components/ui/button';
import {
  Frame,
  ContainerContent,
  Container,
  Grid,
  Stack,
} from '#app/components/layouts';
import { cn, getImageItemUrlByHandle } from '#app/utils/misc';

export default function HomepageMotionComponents({
  productOverview,
  sections,
  customersSupported,
  footer,
  imageCollection,
}: {
  productOverview?: HomepageProps['productOverview'];
  sections?: HPSection[];
  customersSupported?: ImageHeadingRichTextProps[];
  footer: NavItemProps;
  imageCollection: ImageCollection;
}) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });

  const { scrollY } = useScroll();
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const opacity = useTransform(scrollY, [100, 0], [0, 1]);

  useMotionValueEvent(scrollY, 'change', (current) => {
    setHasScrolled(current > 100);
  });

  const getImage = (handle: string, defaultImg: string) => {
    const image = getImageItemUrlByHandle(imageCollection, handle);

    return image ? image : defaultImg;
  };

  const satGradient = getImage('satGradient', '/img/hp/sat-gradient.png');
  const satellite = getImage('satellite', '/img/hp/satellite.png');
  const moon = getImage('moon', '/img/hp/moon.png');
  const cloudAvTopLeft = getImage(
    'cloudAvTopLeft',
    '/img/hp/cloud-av-top-left.png',
  );
  const cloudAvTopRight = getImage(
    'cloudAvTopRight',
    '/img/hp/cloud-av-top-right.png',
  );
  const cloudAvBottomLeft = getImage(
    'cloudAvBottomLeft',
    '/img/hp/cloud-av-bottom-left.png',
  );
  const cloudAvBottomRight = getImage(
    'cloudAvBottomRight',
    '/img/hp/cloud-av-bottom-right.png',
  );
  const airplane = getImage('airplane', '/img/hp/airplane.png');

  const cloudHiLeft = getImage('cloudHiLeft', '/img/hp/cloud-hi-left.png');
  const cloudHiMiddle = getImage(
    'cloudHiMiddle',
    '/img/hp/cloud-hi-middle.png',
  );
  const cloudHiRight = getImage('cloudHiRight', '/img/hp/cloud-hi-right.png');
  const truckMountain = getImage('truckMountain', '/img/hp/truck-mountain.png');
  const dust1 = getImage('dust1', '/img/hp/left-dust.png');
  const dust2 = getImage('dust2', '/img/hp/right-dust.png');
  const exploreGlow = getImage('exploreGlow', '/img/hp/explore-glow.png');

  const moonPos = useTransform(scrollYProgress, [0, 1], ['100%', '0%']);
  const bgStars = useTransform(scrollYProgress, [0, 1], ['-50%', '100%']);
  const text1Y = useTransform(scrollYProgress, [0, 1], ['20%', '100%']);
  // const text2Y = useTransform(scrollYProgress, [0, 1], ['0%', '200%']);
  const textAvionicsY = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);
  const dustOne = useTransform(scrollYProgress, [0, 1], ['-70%', '0%']);
  const dustTwo = useTransform(scrollYProgress, [0, 1], ['70%', '0%']);

  const satPosX = useTransform(scrollYProgress, [0, 1], ['-100%', '200px']);
  // const rotateSat = useTransform(scrollY, [0, 1000], [0, 2], {
  //   clamp: false,
  // });

  const cloudAvTopLeftPos = useTransform(
    scrollYProgress,
    [0, 1],
    ['40%', '0%'],
  );

  const cloudAvTopRightPos = useTransform(
    scrollYProgress,
    [0, 1],
    ['50%', '0%'],
  );

  const cloudAvBottomLeftPos = useTransform(
    scrollYProgress,
    [0, 1],
    ['50%', '0%'],
  );

  const cloudAvBottomRightPos = useTransform(
    scrollYProgress,
    [0, 1],
    ['50%', '0%'],
  );

  const airplanePos = useTransform(scrollYProgress, [0, 1], ['0%', '90%']);

  const cloudHiLeftPos = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);
  const cloudHiMiddlePos = useTransform(scrollYProgress, [0, 1], ['0%', '30%']);
  const cloudHiRightPos = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);

  let spaceData: HPSection | undefined;
  let avionicsData: HPSection | undefined;
  let hiRelCotsData: HPSection | undefined;

  if (sections && sections.length > 0) {
    spaceData = sections[0];
    avionicsData = sections[1];
    hiRelCotsData = sections[2];
  }

  return (
    <div ref={ref}>
      <div className="relative flex h-[2200px] w-full items-center justify-center border-dotted border-black-400 bg-gray-950 md:h-[1564px]">
        <motion.div
          style={{
            opacity,
          }}
          className={cn('z-30 hidden self-start md:flex md:justify-center')}
        >
          <div className="absolute top-4 flex flex-col items-center">
            <span className="mb-2 block text-[11px] text-white">
              Explore VPT
            </span>
            <div className="icon-scroll relative" />
          </div>
          <img
            src={exploreGlow}
            className=""
            alt=""
            width="412"
            height="139"
            loading="lazy"
          />
        </motion.div>

        <div className="absolute top-[100px] z-20 flex w-full flex-col items-center md:top-[171px]">
          <h2 className="mb-5 px-[15px] text-center text-5xl text-white">
            {productOverview?.[0]?.title}
          </h2>
          <div
            className="mb-3 px-[15px] text-center  text-sm leading-6 text-neutral-400 [&>p]:max-w-[575px] [&>p]:text-center"
            dangerouslySetInnerHTML={{
              __html: productOverview?.[0]?.tagline ?? '',
            }}
          />
          <Link
            className="mb-7 text-sm font-medium text-white hover:underline"
            to={`/${productOverview?.[0]?.page?.[0]?.uri}`}
          >
            {`Browse ${productOverview?.[0]?.page?.[0]?.title} `}&rsaquo;
          </Link>

          <ProductTypes types={productOverview?.[0]?.productTypes!} />
        </div>

        <motion.div
          style={{ x: moonPos }}
          className="absolute right-[100px] top-[680px] z-10 hidden md:block lg:right-[320px]"
        >
          <Image
            layout="constrained"
            aspectRatio={1}
            height={603}
            src={moon}
            alt=""
            loading="lazy"
          />
        </motion.div>

        <motion.div
          style={{
            x: satPosX,
            originX: '0%',
            originY: '50%',
          }}
          className="absolute -left-[20px] top-[600px] z-20 hidden md:top-[700px] md:block 2md:left-0 2md:top-[800px] lg:top-[900px]"
        >
          <Image
            layout="constrained"
            width={447}
            height={303}
            src={satellite}
            alt=""
            loading="lazy"
          />
        </motion.div>

        {/* mobile */}
        <motion.div
          style={{
            x: satPosX,
            originX: '0%',
            originY: '50%',
          }}
          className="absolute top-[1300px] z-20 md:hidden"
        >
          <Image
            layout="constrained"
            width={320}
            height={216}
            src={satellite}
            alt=""
          />
        </motion.div>

        <Image
          src={satGradient}
          className={cn(
            'absolute  left-0  z-10 2md:top-[200px] lg:top-[250px]',
            hasScrolled ? 'top-[850px] md:top-[200px] ' : '',
          )}
          alt=""
          width={584}
          height={1467}
          loading="lazy"
        />

        <motion.div
          className="absolute z-0 h-[1000px] md:h-full w-full"
          style={{ y: bgStars }}
        >
          <Canvas>
            <Stars
              radius={50}
              depth={50}
              count={1000}
              factor={4}
              speed={1}
              saturation={50}
              fade
            />
          </Canvas>
        </motion.div>

        <Container className="absolute top-[1600px] h-full w-full md:top-[1100px] 2md:top-[980px] lg:top-[800px]">
          <ContainerContent className="flex justify-center px-[15px] md:justify-end">
            <motion.div
              // style={{ y: text1Y }}
              className="z-40 flex flex-col items-center px-[15px] text-center md:items-start md:px-0 md:text-left"
            >
              <Button
                asChild
                variant="ghost"
                className="mb-5 h-auto px-2 py-1 text-base"
              >
                <Link to="/applications/space" prefetch="intent">
                  Space
                </Link>
              </Button>
              <p className="mb-[45px] w-full text-center text-5xl text-white sm:w-[600px] md:text-left">
                {spaceData?.title}
              </p>

              {spaceData?.list && spaceData.list.length > 0 ? (
                <List data={spaceData} />
              ) : null}
            </motion.div>
          </ContainerContent>
        </Container>
      </div>
      <div className="relative flex h-[2500px] w-full justify-center">
        <div className="relative z-0 h-full w-full bg-gradient-homepage-sky" />
        <motion.div
          // style={{ y: text2Y }}
          className="absolute top-[250px] z-20 flex flex-col items-center px-[15px] text-center xs:top-[250px] sm:px-0 md:top-[300px]"
        >
          <Button
            asChild
            variant="ghost"
            className="mb-5 h-auto px-2 py-1 text-base"
          >
            <Link to="/applications/avionics-and-military" prefetch="intent">
              Avionics & Military
            </Link>
          </Button>
          <p className="mb-8 text-center text-5xl text-white sm:w-[440px]">
            {avionicsData?.title}
          </p>
          {avionicsData?.list &&
          avionicsData?.list.length > 0 &&
          avionicsData.list[0].typeHandle === 'generalLink' ? (
            <Link
              to={avionicsData.list[0].link}
              className="flex h-11 w-[146px] items-center justify-center border border-neutral text-sm text-white hover:bg-white/10"
              prefetch="intent"
            >
              {avionicsData.list[0].title}
            </Link>
          ) : null}
        </motion.div>

        <motion.img
          style={{ x: cloudAvTopLeftPos }}
          src={cloudAvTopLeft}
          className="absolute -left-[80%] top-[600px] xs:top-[500px] sm:top-[200px]"
          alt=""
          width={1674}
          height={591}
          loading="lazy"
        />

        <motion.img
          style={{ x: cloudAvTopRightPos }}
          src={cloudAvTopRight}
          className="absolute -right-[80px] top-[600px] xs:top-[500px] sm:-right-[300px] sm:top-[200px]"
          width={1302}
          height={436}
          alt=""
          loading="lazy"
        />

        <motion.img
          style={{ x: cloudAvBottomLeftPos }}
          src={cloudAvBottomLeft}
          className="absolute -left-[60%] top-[650px] xs:top-[550px] sm:-left-[70%] sm:top-[500px] 2md:-left-[40%]"
          alt=""
          width={1116}
          height={596}
          loading="lazy"
        />

        <motion.img
          style={{ x: cloudAvBottomRightPos }}
          src={cloudAvBottomRight}
          className="absolute -right-[130px] top-[650px] xs:top-[550px] sm:-right-[30%] sm:top-[500px] md:-right-[40%] lg:right-0"
          alt=""
          loading="lazy"
        />

        <motion.img
          style={{ x: airplanePos }}
          src={airplane}
          className="absolute -left-[70%] top-[640px] xs:top-[540px] sm:top-[500px] lg:-left-[40%]"
          alt=""
          width={966}
          height={280}
          loading="lazy"
        />

        {avionicsData?.infoCards && avionicsData.infoCards.length > 0 ? (
          <motion.div
            style={{ y: textAvionicsY }}
            className="absolute left-0 top-[700px] flex w-full flex-col items-center gap-y-5 px-[15px] xs:gap-x-5 sm:left-auto sm:w-[610px] md:top-[840px] md:w-[700px] md:flex-row md:items-start md:gap-x-[35px] lg:w-[850px]"
          >
            {avionicsData.infoCards[0]?.cards.map((item) => (
              <div
                key={item.id}
                className="flex flex-col items-center text-white md:w-1/3 md:items-start"
              >
                <img
                  src={item.image?.[0]?.url}
                  className="mb-2.5"
                  width={item.image?.[0]?.width}
                  height={item.image?.[0]?.height}
                  alt=""
                />
                <h4 className="mb-2 w-[186px] text-center text-xl md:w-full md:text-left lg:w-[186px]">
                  {item.heading}
                </h4>
                <p className="line-clamp-2 w-[210px] text-center text-sm text-white/70 md:text-left">
                  {item.subheading}
                </p>
              </div>
            ))}
          </motion.div>
        ) : null}

        <motion.img
          style={{ x: cloudHiLeftPos }}
          src={cloudHiLeft}
          className="absolute -left-[50%] bottom-[900px]"
          width={847}
          height={299}
          alt=""
          loading="lazy"
        />

        <Container className="hi-rel 2md:top-[50px]x z-40x absolute bottom-[1000px] top-auto h-full w-full md:bottom-[760px]">
          <ContainerContent className="flex h-full w-full items-end justify-center px-[15px] md:justify-start md:px-[30px] lg:px-[15px]">
            <motion.div
              style={{ y: text1Y }}
              className="relative top-[200px] z-40 flex flex-col items-center px-[15px] text-center md:top-0 md:items-start md:px-0 md:text-left"
            >
              <Button
                asChild
                variant="ghost"
                className="mb-5 h-auto px-2 py-1 text-base"
              >
                <Link to="/applications/hi-rel-cots" prefetch="intent">
                  Hi-Rel COTS
                </Link>
              </Button>
              <p className="mb-[25px] w-full text-center text-5xl text-white sm:w-[600px] md:text-left">
                {hiRelCotsData?.title}
              </p>

              {hiRelCotsData?.list && hiRelCotsData.list.length > 0 ? (
                <List data={hiRelCotsData} />
              ) : null}
            </motion.div>
          </ContainerContent>
        </Container>

        <motion.div
          style={{ x: cloudHiMiddlePos }}
          className="absolute bottom-[600px] right-[40%]"
        >
          <Image
            layout="constrained"
            width={249}
            height={147}
            src={cloudHiMiddle}
            alt=""
            loading="lazy"
          />
        </motion.div>

        {/* <motion.img
          style={{ x: cloudHiRightPos }}
          src={cloudHiRight}
          className="absolute bottom-[630px] right-[10%]"
          alt=""
        /> */}

        <motion.div
          style={{ x: cloudHiRightPos }}
          className="absolute bottom-[630px] right-[10%]"
        >
          <Image
            layout="constrained"
            width={444}
            height={236}
            src={cloudHiRight}
            alt=""
            loading="lazy"
          />
        </motion.div>

        <Frame className="absolute bottom-0 z-20 h-[612px] w-full 2xl:h-[700px] 3xl:h-[850px]">
          <motion.img
            style={{ x: dustOne }}
            src={dust1}
            className="border-error-300x borderx absolute !bottom-0 !top-auto z-40 !h-[372px]"
            alt=""
            loading="lazy"
          />

          <Image
            layout="constrained"
            aspectRatio={1}
            width={3000}
            src={truckMountain}
            className="!object-[-850px] sm:!object-center"
            alt=""
            loading="lazy"
          />

          <motion.img
            style={{ x: dustTwo }}
            src={dust2}
            className="borderx border-errorx absolute !bottom-0 !top-auto z-40 !h-[372px]"
            alt=""
            loading="lazy"
          />
        </Frame>
      </div>

      {customersSupported && customersSupported.length > 0 ? (
        <Container className="bg-gray">
          <ContainerContent className="px-[15px] pb-16 pt-[55px] md:px-[30px] lg:px-[15px]">
            <div className="mb-8 flex flex-col md:mb-5 md:flex-row md:items-center md:justify-between">
              <div className="flex md:items-center">
                <h2 className="mb-5 text-3xl font-medium text-white md:mb-0">
                  {customersSupported[0]?.heading}
                </h2>
              </div>
              <Button
                asChild
                variant="link"
                className="h-auto !justify-start gap-2 p-0 md:justify-center"
              >
                <Link
                  to={
                    customersSupported[0]?.subheading ??
                    '/company/customers-supported'
                  }
                  prefetch="intent"
                >
                  <span>All Supported Customers</span>
                  <ArrowRight size={24} />
                </Link>
              </Button>
            </div>
            <div
              className="text-xs text-neutral-400/50 [&_div.raw-html-embed]:flex [&_div.raw-html-embed]:items-center [&_figure>img]:max-h-[40px] [&_figure>img]:object-contain [&_figure>img]:object-left [&_figure>img]:opacity-60 [&_figure]:max-h-[40px] [&_ul>li:nth-child(2n+1)]:pl-0 [&_ul>li:nth-child(4n)>div]:border-r-0 md:[&_ul>li:nth-child(4n+1)]:pl-0 [&_ul>li:nth-child(4n+2)>div]:border-r-0 md:[&_ul>li:nth-child(4n+2)>div]:border-r md:[&_ul>li:nth-child(4n+3)]:pl-5 [&_ul>li:nth-last-child(-n+2)]:border-b-0 md:[&_ul>li:nth-last-child(-n+4)]:border-b-0 [&_ul>li>div]:my-2.5 [&_ul>li>div]:block [&_ul>li>div]:h-[calc(100%_-_20px)] [&_ul>li>div]:border-r [&_ul>li>div]:border-dashed [&_ul>li>div]:border-black-400/30 [&_ul>li>div]:py-1 [&_ul>li>div]:pr-5 [&_ul>li>div]:font-normal [&_ul>li]:border-b [&_ul>li]:border-dashed [&_ul>li]:border-black-400/30 [&_ul>li]:pl-5 [&_ul]:grid [&_ul]:grid-cols-2 md:[&_ul]:grid-cols-4"
              dangerouslySetInnerHTML={{
                __html: customersSupported[0]?.richText ?? '',
              }}
            />
          </ContainerContent>
        </Container>
      ) : null}
      <NewsletterBar />
      <Footer data={footer} />
    </div>
  );
}

function List({ data }: { data: HPSection }) {
  return (
    <ul className="w-full text-xl text-white xs:w-[430px] [&>li:nth-last-child(-n+2)]:border-b-0">
      {data?.list?.map((item, idx) => (
        <li
          key={item.id}
          className={cn(
            'flex justify-center border-b border-white/20 py-5 text-center md:justify-start md:text-left',
            idx === 2 ? 'mb-2' : null,
          )}
        >
          {item.typeHandle === 'generalLink' ? (
            <Button
              asChild
              variant={idx === 0 ? 'link2' : idx === 3 ? 'outline' : 'default'}
              className={cn(
                idx === 0 ? 'p-0 text-xl underline' : null,
                idx === 3
                  ? 'flex h-11 w-[146px] items-center justify-center border border-neutral text-sm text-white hover:bg-white/10'
                  : null,
              )}
            >
              <Link to={item.link} prefetch="intent">
                {item.title}
              </Link>
            </Button>
          ) : (
            item.text
          )}
        </li>
      ))}
    </ul>
  );
}

function ProductTypes({ types }: { types: HPProductType[] }) {
  return (
    <div className="px-[15px] md:px-[30px]">
      <Grid className="sm:grid-cols-2x grid-cols-1 gap-px md:w-full md:grid-cols-3">
        {types.map((item) => (
          <Link
            key={item.id}
            to={item?.relativeLink!}
            className="h-fullx group h-[254px] bg-gray/60 px-5 pb-[30px] pt-5 hover:bg-gradient-series-cta md:h-[280px] lg:w-[292px]"
            prefetch="intent"
          >
            <Stack className="items-start gap-0">
              <Frame className="mb-5 h-[46px] w-20 sm:h-[60px] sm:w-[104px] [&>img]:!object-contain">
                <Image
                  src={item.image?.[0]?.url!}
                  layout="constrained"
                  height={60}
                  width={104}
                  className="absolute left-0 top-0 object-cover object-center sm:object-left"
                  alt={
                    item.image?.[0]?.alt
                      ? item.image?.[0]?.alt
                      : item.image?.[0]?.title
                  }
                />
              </Frame>
              <h3 className="mb-2.5 text-xl text-white">{item.heading}</h3>
              <div
                className="mb-5 text-left text-sm text-neutral-400 *:*:mb-[5px] *:mb-[7px] group-hover:text-white 2md:mb-[30px]"
                dangerouslySetInnerHTML={{
                  __html: item.richText ?? '',
                }}
              />
              <ArrowRight className=" text-white" size={24} />
            </Stack>
          </Link>
        ))}
      </Grid>
    </div>
  );
}
