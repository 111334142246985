import * as React from 'react';
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react';
import { AutoplayType } from 'embla-carousel-autoplay';
import { Image } from '@unpic/react';

import { useDotButton } from './carousel-dot-button';

import { Link } from '@remix-run/react';

import { cn } from '#app/utils/misc.tsx';
import { Frame, Split } from '#app/components/layouts';
import { DotProgress } from './dot-progress';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  onNavButtonClick: (api: CarouselApi) => void;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeftIcon') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRightIcon') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    const onNavButtonClick = React.useCallback((api: CarouselApi) => {
      const autoplay: AutoplayType = api?.plugins()?.autoplay!;
      if (!autoplay) return;

      const resetOrStop =
        autoplay.options.stopOnInteraction === false
          ? autoplay.reset
          : autoplay.stop;

      resetOrStop();
    }, []);

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          orientation:
            orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          onNavButtonClick,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn(
            'relative z-30 hidden md:flex h-screen flex-col items-center overflow-hidden md:h-[calc(100vh_-_100px)]',
            className,
          )}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef } = useCarousel();

  return (
    <div ref={carouselRef} className="relative z-10 w-full">
      <div
        ref={ref}
        className={cn('flex items-center', className)}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

type CarouselControlsProps = {
  slides: HomepageCarouselItemProps[];
  delay: number;
};
const CarouselControls = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselControlsProps
>(({ className, slides, delay, ...props }, ref) => {
  const { api, onNavButtonClick } = useCarousel();
  const { selectedIndex, scrollSnaps } = useDotButton(api, onNavButtonClick);

  type TempFeatured = {
    [key: string]: {
      [key: string]: boolean | undefined;
    };
  };

  let featuredThumbnailIds: string[] = [];
  slides.reduce<TempFeatured>((acc, cv) => {
    if (acc[cv.applicationCategory?.[0].slug]) {
      const firstCategorySlide = acc[cv.applicationCategory?.[0].slug];

      for (const [firstKey, firstValue] of Object.entries(firstCategorySlide)) {
        let featured = '';
        let notFeatured = '';
        if (firstValue !== cv.isFeaturedThumbnail) {
          if (firstValue) {
            featured = firstKey;
            notFeatured = cv.id;
          }

          if (cv.isFeaturedThumbnail) {
            featured = cv.id;
            notFeatured = firstKey;
          }
        } else {
          featured = firstKey;
          notFeatured = cv.id;
        }

        featuredThumbnailIds.push(featured);
        featuredThumbnailIds.push(notFeatured);
      }
    } else {
      acc[cv.applicationCategory?.[0].slug] = {
        [cv.id]: cv.isFeaturedThumbnail,
      };
    }

    return acc;
  }, {});

  const sortedSlides = slides
    .sort((a, b) => {
      return (
        featuredThumbnailIds.indexOf(a.id) - featuredThumbnailIds.indexOf(b.id)
      );
    })
    .filter((item) => featuredThumbnailIds.includes(item.id));

  return (
    <div
      className={cn(
        'absolute bottom-[82px] z-30 flex h-[84px] flex-col justify-center gap-y-px md:bottom-[42px] md:flex-row',
        className,
      )}
      {...props}
    >
      {scrollSnaps.map((_, idx) => {
        return (
          <div className="relative" key={sortedSlides[idx].id}>
            {idx === selectedIndex ? (
              <DotProgress
                delay={delay}
                isSelected={true}
                idx={idx}
                className={cn(
                  'absolute',
                  idx % 2
                    ? 'md:-left-[118px] 2md:-left-[135px] lg:-left-[147px]'
                    : '',
                )}
              />
            ) : (
              <div
                className={cn(
                  'absolute z-30 bg-[rgba(78,90,102,0.45)] md:h-[1.54px] md:w-[118px] 2md:w-[135px] lg:w-[147px]',
                  idx % 2
                    ? 'md:-left-[118px] 2md:-left-[135px] lg:-left-[147px]'
                    : '',
                  selectedIndex === 0 && idx === 1 ? 'bg-white' : '',
                  selectedIndex === 1 && idx === 0 ? 'bg-primary' : '',
                  selectedIndex === 2 && idx === 3 ? 'bg-white' : '',
                  selectedIndex === 3 && idx === 2 ? 'bg-primary' : '',
                  selectedIndex === 4 && idx === 5 ? 'bg-white' : '',
                  selectedIndex === 5 && idx === 4 ? 'bg-primary' : '',
                )}
              />
            )}
            <Link
              to={sortedSlides[idx].applicationCategory?.[0].frontendUri}
              className={cn(
                '2xs:w-[307px] inline-block w-full md:w-[236px] 2md:w-[270px] lg:w-[294px]',
                'border-xx border-gray/50x h-[84px] bg-gray/60 hover:bg-gradient-hp-dot',
                idx === 2 ? 'border-l border-gray/50' : '',
                idx === 4 ? 'border-l border-gray/50' : '',
                className,
                idx % 2 === 0 ? '' : 'hidden',
              )}
              prefetch="intent"
            >
              <Split className="h-[84px] flex-shrink-0 grid-cols-[103px_1fr] items-start px-2 pt-2 md:p-[9px]">
                <Frame className="mb-2x h-[65px] w-[90px]">
                  <Image
                    height={65}
                    width={90}
                    src={sortedSlides[idx].sliderThumbnail?.[0]?.url!}
                    className="z-10"
                    alt={`Background image for ${sortedSlides[idx].applicationCategory?.[0].title}`}
                  />
                </Frame>
                <div>
                  <h2
                    className={cn(
                      'mb-1.5 text-left text-base font-medium text-white',
                      idx !== 0
                        ? 'mb-1.5 !leading-5 sm:mb-[3px] lg:mb-1.5 lg:leading-7'
                        : '',
                    )}
                  >
                    {sortedSlides[
                      idx
                    ].applicationCategory?.[0].title.replaceAll('and', '&')}
                  </h2>
                  <h3
                    className={cn(
                      'text-left text-[10.79px] leading-[15px] text-neutral-400',
                      idx === 1 ? 'leading-3 lg:leading-[15px]' : '',
                    )}
                  >
                    {sortedSlides[idx].itemSubtext}
                  </h3>
                </div>
              </Split>
            </Link>
          </div>
        );
      })}
    </div>
  );
});

CarouselControls.displayName = 'CarouselControls';

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        'embla-slide min-w-0 shrink-0 grow-0 basis-full',
        className,
      )}
      {...props}
    />
  );
});
CarouselItem.displayName = 'CarouselItem';

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselControls,
};
