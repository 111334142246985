import { json, useLoaderData } from '@remix-run/react';
import { type MetaFunction } from '@remix-run/node';
import he from 'he';

import HeroCarousel from './blocks/homepage-hero-carousel';
import HomepageMotionComponents from './blocks/homepage-motion-components';
// import LogosCarousel from './blocks/logos-carousel';
import HomepageHeroMobile from './blocks/homepage-hero-mobile';
import TopBar from '#app/components/top-bar';
import { getNavData } from '#app/models/nav.server';
import { getHomepageData } from '#app/models/single.server';
import { setMetaTags } from '#app/utils/seomatic';
import { getEnv } from '#app/utils/env.server';

export async function loader() {
  const [navResponse, homepageResponse] = await Promise.all([
    getNavData(),
    getHomepageData(),
  ]);

  const envVars = getEnv();

  const {
    data: { header, footer, general },
  } = navResponse;

  const {
    data: { homepageData },
  } = homepageResponse;

  return json({
    homepageData,
    header,
    footer,
    general,
    envVars,
  });
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data?.homepageData) {
    return [{ title: `Page Not Found | VPT, Inc.` }];
  }

  const seomatic = data?.homepageData.seomatic;
  const pageTitle = data?.homepageData.title;

  if (!seomatic) {
    return [{ title: `${pageTitle} | VPT, Inc.` }];
  }

  const {
    title,
    generator,
    description,
    keywords,
    robots,
    ogLocale,
    ogSiteName,
    ogType,
    ogTitle,
    ogDescription,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    ogUrl,
    twitterCard,
    canonical,
    home,
    jsonLd,
  } = setMetaTags(seomatic);

  return [
    { title: he.decode(title) },
    { name: 'generator', content: generator },
    { name: 'description', content: he.decode(description) },
    { name: 'keywords', content: keywords },
    { name: 'robots', content: robots },
    { property: 'og:locale', content: ogLocale },
    { property: 'og:site_name', content: ogSiteName },
    { property: 'og:type', content: ogType },
    { property: 'og:url', content: ogUrl },
    { property: 'og:title', content: ogTitle },
    { property: 'og:title', content: he.decode(ogTitle) },
    { property: 'og:description', content: he.decode(ogDescription) },
    { property: 'og:image:width', content: ogImageWidth },
    { property: 'og:image:height', content: ogImageHeight },
    { name: 'twitter:card', content: twitterCard },
    { tagName: 'link', rel: 'canonical', href: canonical },
    { tagName: 'link', rel: 'home', href: home },
    { 'script:ld+json': JSON.parse(jsonLd) },
  ];
};

export default function Homepage() {
  const { homepageData, header, footer, general, envVars } =
    useLoaderData<typeof loader>();

  return (
    <div className="grid-cols-[100%]x grid min-h-full overflow-hidden">
      <div className="min-h-screen md:min-h-[calc(100vh_-_100px)] ">
        <TopBar
          isHomepage={true}
          data={header}
          notification={general}
          envVars={envVars}
        />
        {/* <div className="relative"> */}
        <HomepageHeroMobile data={homepageData} general={general}/>
        <HeroCarousel data={homepageData} />
        {/* <LogosCarousel items={homepageData?.logos} /> */}
        {/* </div> */}
        <HomepageMotionComponents
          productOverview={homepageData?.productOverview}
          sections={homepageData?.sections}
          customersSupported={homepageData?.programsSupported}
          footer={footer}
          imageCollection={homepageData?.imageCollection}
        />
      </div>
    </div>
  );
}
