import { Image } from '@unpic/react';
import { Link } from '@remix-run/react';

import { Frame, Split } from '#app/components/layouts';
import { cn } from '#app/utils/misc.tsx';

function HomepageHeroMobile({
  data,
  general,
}: {
  data: HomepageProps;
  general: GeneralProps;
}) {
  const slides = data?.carouselItems?.filter(
    (item) => item.isFeaturedThumbnail,
  );

  // console.log('slides', slides);

  return (
    <div
      className={cn(
        'flex md:hidden flex-col items-center relative w-full h-[660px] overflow-hidden bg-[#183c56] !aspect-auto',
        general?.enableNotificationBar ? 'top-[66px]' : '',
      )}
    >
      <div className="absolute bottom-[82px] z-30 flex h-[84px] flex-col justify-center gap-y-px">
        {slides?.map((item) => {
          return (
            <div key={item.id} className="relative">
              <Link
                to={item.applicationCategory?.[0].frontendUri}
                className={cn(
                  '2xs:w-[307px] inline-block w-full md:w-[236px] 2md:w-[270px] lg:w-[294px]',
                  'border-xx border-gray/50x h-[84px] bg-gray/60 hover:bg-gradient-hp-dot border-gray/50',
                )}
                prefetch="intent"
              >
                <Split className="h-[84px] flex-shrink-0 grid-cols-[103px_1fr] items-start px-2 pt-2 md:p-[9px]">
                  <div className="mb-2x h-[65px] w-[90px] relative overflow-hidden">
                    <Image
                      height={65}
                      width={90}
                      src={item.sliderThumbnail?.[0]?.url!}
                      className="z-10 w-full h-full absolute object-cover"
                      alt={`Background image for ${item.applicationCategory?.[0].title}`}
                    />
                  </div>
                  <div>
                    <h2
                      className={cn(
                        'mb-1.5 text-left text-base font-medium text-white',
                      )}
                    >
                      {item.applicationCategory?.[0].title.replaceAll(
                        'and',
                        '&',
                      )}
                    </h2>
                    <h3
                      className={cn(
                        'text-left text-[10.79px] leading-[15px] text-neutral-400',
                      )}
                    >
                      {item.itemSubtext}
                    </h3>
                  </div>
                </Split>
              </Link>
            </div>
          );
        })}
      </div>
      <Image
        src={slides?.[0]?.itemImage?.[0]?.urlSm!}
        layout="constrained"
        aspectRatio={1}
        width={800}
        priority={true}
        className="object-cover h-full w-full z-10"
      />
    </div>
  );
}

export default HomepageHeroMobile;
