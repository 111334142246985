import * as React from 'react';

import { cn } from '#app/utils/misc.tsx';
import { Progress } from '#app/components/ui/progress';

type IntervalType = string | number | NodeJS.Timeout | undefined;
let interval: IntervalType = undefined;

export function DotProgress({
  delay,
  isSelected,
  idx,
  className,
}: {
  delay: number;
  isSelected: boolean;
  idx: number;
  className?: string;
}) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (isSelected && delay) {
      interval = setInterval(
        () => {
          setProgress((prev) => prev + 1);
        },
        Math.ceil(delay / 100),
      );
    } else {
      setProgress(0);
      clearInterval(interval);
    }
  }, [isSelected, delay]);

  React.useEffect(() => {
    if (progress === 100) {
      clearInterval(interval);
    }
  }, [progress]);

  // console.log('progress', progress, idx);
  return (
    <Progress
      max={100}
      value={progress}
      className={cn(
        'z-30 hidden h-[1.54px] rounded-none md:block md:w-[118px] 2md:w-[135px] lg:w-[147px]',
        isSelected ? 'bg-white' : 'bg-[rgba(78,90,102,0.45)]',
        className,
      )}
    />
  );
}
