import { useState, useEffect } from 'react'

const useScreenSize = () => {
	if (typeof document === 'undefined') return

	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener('resize', handleResize)

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	let xs = false, sm = false, md = false, lg = false;

	if (screenSize.width >= 1024) {
		lg = true;
	}

	if (screenSize.width < 1024) {
		md = true;
	}

	if (screenSize.width < 768) {
		sm = true;
	}

	if (screenSize.width <= 425) {
		xs = true;
	}

	return { xs, sm, md, lg }
}

export default useScreenSize
